import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Link as Scroll } from 'react-scroll';
import './App.css';

import ParticleComponent from './components/ParticleComponent';
import About from './components/About';
import Projects from './components/Projects';
import EmailForm from './components/EmailForm';
import Socials from './components/Socials';

const App = () => {
  return (
    <Router>
      <div>
        <ParticleComponent />
        <div className='banner-wrap'>
          <div className='banner-text'>
            Hello, I'm
            <span style={{ color: '#edc712', fontWeight: '600' }}>
              {' '}
              Bogdan Matei
            </span>
            .
            <br />
            I'm a front-end developer.
          </div>
          <Scroll
            className='js-scroll-trigger'
            smooth={true}
            to='projects'
            duration={100}
          >
            <div className='banner-button'>View my projects</div>
          </Scroll>
        </div>
      </div>
      <About />
      <Projects />
      <EmailForm />
      <Socials />
    </Router>
  );
};

export default App;
