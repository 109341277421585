import React, { Component } from 'react';
import Modal from './Modal';
import { Carousel } from 'react-bootstrap';
import './css/Panel.css';

class Panel extends Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.state = { show: false };
  }

  handleClick() {
    if (!this.state.show) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState((prevState) => ({
      show: !prevState.show,
    }));
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }

    this.handleClick();
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };
  render() {
    return (
      <div className='project-card'>
        
        <Modal show={this.state.show} handleClose={this.hideModal}>
          <div
            ref={(node) => {
              this.node = node;
            }}
          >
            <Carousel indicators={false} interval={null}>
              <Carousel.Item>
                <img
                  className='d-block w-100 '
                  src={this.props.img1}
                  alt='First slide'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100'
                  src={this.props.img2}
                  alt='Second slide'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100'
                  src={this.props.img3}
                  alt='Third slide'
                />
              </Carousel.Item>
              {this.props.img4 ? (
                <Carousel.Item>
                  <img
                    className='d-block w-100'
                    src={this.props.img4}
                    alt='Fourth slide'
                  />
                </Carousel.Item>
              ) : (
                ''
              )}
            </Carousel>
            <div className='project-details'>
              <h2>{this.props.title}</h2>
              <hr />
              <p>{this.props.description}</p>
              <p>{this.props.purpose}</p>
              <hr />
            </div>
            <div className='media-buttons'>
              <a
                href={this.props.website}
                target='_blank'
                rel='noopener noreferrer'
              >
                Live Version
              </a>
              <br />
              <a
                href={this.props.github}
                target='_blank'
                rel='noopener noreferrer'
              >
                View code
              </a>
            </div>
          </div>
        </Modal>

        <div className='card-wrapper'>
          <img
            src={this.props.img1}
            className='card-img-top'
            alt='Landing page'
          />
          <div className='card-overlay'>
            <div className='card-content'>
              <h5 className='card-title'>{this.props.title}</h5>
              <button type='button' onClick={this.handleClick}>
                More
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Panel;
