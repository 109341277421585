import React from 'react';
import Particles from 'react-particles-js';
import './css/Particles.css';

const ParticleContainer = () => (
  <Particles
    style={{
      backgroundColor: '#191919',
      width: '100vw',
      height: '100vh',
    }}
    params={{
      particles: {
        number: {
          value: 50,
          density: {
            enable: true,
            value_area: 789.1476416322727,
          },
        },
        color: {
          value: '#edc712',
        },
        shape: {
          type: 'polygon',
          stroke: {
            width: 0,
            color: '#000000',
          },
          polygon: {
            nb_sides: 6,
          },
        },
        opacity: {
          value: 0.4008530152163807,
          random: false,
          anim: {
            enable: true,
            speed: 0.16241544246026904,
            opacity_min: 0,
            sync: false,
          },
        },
        size: {
          value: 4,
          random: true,
          anim: {
            enable: false,
            speed: 4,
            size_min: 0.3,
            sync: false,
          },
        },
        line_linked: {
          enable: false,
          distance: 150,
          color: '#ffffff',
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 0.5,
          direction: 'bottom',
          random: true,
          straight: false,
          out_mode: 'out',
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 600,
          },
        },
      },
      interactivity: {
        detect_on: 'window',
        events: {
          onhover: {
            enable: true,
            mode: 'grab',
            parallax: {
              enable: true,
              force: 15,
              smooth: 200,
            },
          },
          onclick: {
            enable: false,
            mode: 'repulse',
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 250,
            line_linked: {
              opacity: 0.1316200786429971,
            },
          },
          bubble: {
            distance: 250,
            size: 0,
            duration: 2,
            opacity: 0,
            speed: 3,
          },
          repulse: {
            distance: 400,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    }}
  />
);

export default ParticleContainer;
