import React, { Component } from 'react';
import './css/Socials.css';
import Resume from '../images/Resume.pdf'

export class Socials extends Component {
  render() {
    return (
      <footer>
        <div className='footer-container'>
          <ul className='social-links'>
            <li>
              <a
                title="LinkedIn"
                href='https://www.linkedin.com/in/mateibogdan/'
                aria-label='LinkedIn'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fab fa-3x fa-linkedin-in linkedin'></i>
              </a>
            </li>
            <li>
              <a
                title="GitHub"
                href='https://github.com/bgdmatei'
                aria-label='GitHub'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fab fa-3x fa-github github'></i>
              </a>
            </li>
            <li>
              <a
                title="Twitter"
                href='https://twitter.com/bgdmatei'
                aria-label='Twitter'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fab fa-3x fa-twitter twitter'></i>
              </a>
            </li>
            <li>
              <a
                title="Resume"
                href={Resume}
                aria-label='Resume'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fa-3x fas fa-save save'></i>
              </a>
            </li>
          </ul>
        </div>
      </footer>
    );
  }
}

export default Socials;
