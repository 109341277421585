import React, { Component } from 'react';
import './css/Projects.css';
import Panel from './Panel';

//img imports
import ronp1 from '../images/landing.jpg';
import ronp2 from '../images/browse.jpg';
import ronp3 from '../images/debate.jpg';
import ronp4 from '../images/dashboard.jpg';

import swap1 from '../images/swap1.jpg';
import swap2 from '../images/swap2.jpg';
import swap3 from '../images/swap3.jpg';
import swap4 from '../images/swap4.jpg';

import ehc1 from '../images/ehcmain.jpg';
import ehc2 from '../images/ehcabout.jpg';
import ehc3 from '../images/ehcservice.jpg';
import ehc4 from '../images/ehccontact.jpg';

import mlh1 from '../images/mlhlanding.jpg';
import mlh2 from '../images/mlhfront.jpg';
import mlh3 from '../images/mlhcomment.jpg';
import mlh4 from '../images/mlhsignup.jpg';

import ws1 from '../images/wslanding.jpg';
import ws2 from '../images/wsproduct.jpg';
import ws3 from '../images/wspayment.jpg';
import ws4 from '../images/wsadmin.jpg';

import dd1 from '../images/landingddd.jpg';
import dd2 from '../images/servicesddd.jpg';
import dd3 from '../images/aboutddd.jpg';
import dd4 from '../images/reviewsddd.jpg';

class Projects extends Component {
  render() {
    const projects = [
      {
        title: 'D&D Deep Cleaning Services',
        description: `Website built for a local cleaning company.`,
        purpose: `Freelance project`,
        website: 'https://dddeepcleaningservices.co.uk/',
        github: 'https://github.com/bgdmatei/ddcleaningservice',
        img1: dd1,
        img2: dd2,
        img3: dd3,
        img4: dd4,
      },
      {
        title: 'WonderSoft',
        description: `eCommerce platform built with the MERN stack & Redux.`,
        purpose: `Built for the purpose of learning the MERN stack(MongoDB, Express, React, Node), PayPal / credit card integration but also to improve my Redux knowledge`,
        website: 'https://wondersoft-mern.herokuapp.com/',
        github: 'https://github.com/bgdmatei/wondersoft-mern',
        img1: ws1,
        img2: ws2,
        img3: ws3,
        img4: ws4,
      },
      {
        title: "Mommy's Little Helper",
        description:
          "Are you a new parent in search of new food recipes or tips on raising your child? Mommy's Little Helper lets you get in contact with other parents that are happy to share their experience.",
        purpose: `This website was built to further enhance my knowledge of React, and to get used to newer technologies such as Express, Redux.`,
        role: 'Responsible for the entire design and development.',
        website: 'https://mommyslittlehelper.netlify.app/',
        github: 'https://github.com/bgdmatei/mommyslittlehelper',
        img1: mlh1,
        img2: mlh2,
        img3: mlh3,
        img4: mlh4,
      },
      {
        title: 'Eagle House Construction',
        description:
          'Website built for a local construction company. It presents their services, a small description of the company and a gallery of their recent works.',
        purpose: `First project built with React to learn the fundamentals.`,
        role: 'Responsible for the entire design and development.',
        website: 'https://eaglehouseconstruction.co.uk/',
        github: 'https://github.com/bgdmatei/eaglehouseconstruction',
        img1: ehc1,
        img2: ehc2,
        img3: ehc3,
        img4: ehc4,
      },
      {
        title: 'Ronpa!',
        description:
          'A brand-new debate platform which enables live discussions of any chosen topic. As daunting as debates may seem, we wanted to turn them to something more fun and more approachable, that anyone could take part of.',
        purpose: `Leveraged knowledge in Full Stack Development, Ruby on Rails, JavaScript, HTML5, CSS3, Git
        `,
        role: 'UI/UX Designer, Front-End Developer',
        website: 'https://ronpas.herokuapp.com/',
        github: 'https://github.com/bgdmatei/ronpa',
        img1: ronp1,
        img2: ronp2,
        img3: ronp3,
        img4: ronp4,
      },
      {
        title: 'Swapsies',
        description:
          'A web app that allows exchanges with any other registered gamers. With listings and requests, swapping old used games with other game enthusiasts’ games has never been easier. A new way to experience a wider range of games, allowing you to venture into different categories you would not have dared with the current overpriced used game market.',
        purpose: `The focus was on building a robust database where users could connect and interface with other users via a “swap”.`,
        role: 'UI/UX Designer, Front-End Developer',
        website: 'http://gameswapsies.herokuapp.com/',
        github: 'https://github.com/bgdmatei/swapsies',
        img1: swap1,
        img2: swap2,
        img3: swap3,
        img4: swap4,
      },
    ];
    return (
      <section id='projects'>
        <div className='header'>PROJECTS</div>
        <div className='panels'>
          {projects.map((project, index) => {
            return <Panel {...project} key={index} />;
          })}
        </div>
      </section>
    );
  }
}

export default Projects;
