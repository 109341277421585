import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './css/EmailForm.css';

export default class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: '',
    };
  }

  render() {
    const { status } = this.state;
    return (
      <section id='contact'>
        <div className='header'>CONTACT</div>

        <Container id='contact-container'>
          <p>
            Please feel free to contact me for more details regarding my
            websites.
            <br />I would also be happy to collaborate on any upcoming projects!
          </p>
          <Row id='contact-row'>
            <Col lg='12' className='contact-left'>
              <form
                onSubmit={this.submitForm}
                action='https://formspree.io/xzbzjagl'
                method='POST'
              >
                <label>
                  Name
                  <input type='text' name='name' />
                </label>
                <label>
                  Email
                  <input type='email' name='email' />
                </label>
                <label>
                  Message
                  <textarea name='message' type='text' />
                </label>

                {status === 'SUCCESS' ? (
                  <p>Thank you !</p>
                ) : (
                  <button>Send</button>
                )}
                {status === 'ERROR' && <p>Ooops! There was an error.</p>}
              </form>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: 'SUCCESS' });
      } else {
        this.setState({ status: 'ERROR' });
      }
    };
    xhr.send(data);
  }
}
